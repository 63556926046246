.cart_page_table {
  width: 100%;
  padding: 10px;
}
.cart_page_table th {
  padding: 10px 5px;
  text-align: center;
}
.cart_page_table td {
  padding: 10px;
  text-align: center;
}

.table_header,
.table_foot {
  background-color: rgb(215, 215, 215);
}

.cart_page_table th img {
  width: 20px;
}

.cart_prod_name {
  font-size: 14px;
  width: 100px;
}

.cart_row_btns {
  display: flex;
  align-items: center;
  gap: 5px;
}

.cart_row_btns .cart_row_btn {
  width: 20px;
  height: 20px;
  background-color: var(--common-color);
  display: flex;
  align-items: center;
  color: #fff;
  justify-content: center;
}

.cart_product {
  display: flex;
  align-items: center;
  gap: 5px;
}

.cart_product .product_img {
  width: 50px;
}

.cart_product .product_img img {
  width: 100%;
}

.cart_product .product_details {
  display: flex;
  flex-direction: column;
  align-content: space-between;
  gap: 10px;
}

.cart_product .product_details .product_name {
  font-size: 15px;
  font-weight: bolder;
}

.cart_product .product_details .product_count {
  color: gray;
  font-size: 12px;
  font-weight: bolder;
}

.table_foot {
  margin-top: 100%;
}

.cart_page_table.footer {
  position: fixed;
  bottom: 80px;
}

/* --------------------------------- */
/* ------------NEW DESIGN----------- */
/* --------------------------------- */
.cart_content {
  margin-top: 20px;
  padding: 10px;
  margin-bottom: 100px;
  margin-bottom: 200px;
}

.cart_content .cart_product_2 {
  width: 100%;
  padding: 5px;
  border-radius: 20px;
  background-color: #fff;
  box-shadow: 0 0 10px #dadada;
  position: relative;
  margin-block: 10px;
}

.remove_cart_button {
  position: absolute;
  width: 25px;
  height: 25px;
  border-radius: 5px;
  background-color: hsl(0, 83%, 40%);
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  right: 0;
  color: #ffdddd;
}

.enVersion .remove_cart_button {
  left: 0;
  right: auto;
}

.cart_content .cart_product_2 .card_prod_top {
  display: flex;
  align-items: center;
  /* border: 1px solid; */
}

.cart_content .cart_product_2 .cart_prod_image {
  width: 100px;
  height: 100px;
  margin: 10px;
}

.cart_content .cart_product_2 .cart_prod_image img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  background-color: #d3d3d3;
  box-shadow: 0 0 10px #e0e0e0;
  border-radius: 10px;
}

.cart_prod_info {
  flex: 1;
}

.cart_prod_info h4 {
  font-size: 15px;
  color: #696969;
}

.cart_prod_info h5 {
  font-size: 20px;
  color: var(--common-color);
}

.cart_prod_info h5 span {
  font-weight: bolder;
}

.cart_product_2 .count_buttons {
  display: flex;
  height: 100%;
  flex-direction: column;
  align-items: center;
  align-content: space-between;
  justify-content: space-between;
  gap: 10px;
}

.cart_product_2 .count_buttons .count_btn {
  width: 30px;
  height: 30px;
  background-color: var(--common-color);
  color: #fff;
  align-items: center;
  justify-content: center;
  display: flex;
  cursor: pointer;
  border-radius: 10px;
  transition: 0.2s ease;
}

.none_subermarkets{
  display: none !important
}

.cart_product_2 .count_buttons .count_btn:active {
  background-color: #450c7f;
}

.marketName {
  font-size: 12px;
}

.marketPrice {
  font-size: 12px;
}

.marketPrice.active {
  background-color: var(--common-color);
  color: #fff;
}

.fixed_subermarkets {
  position: fixed;
  bottom: 2px;
  left: 0;
  right: 0;
  transform: translateY(-100%);
  background-color: #fff;
  border-bottom: 2px solid var(--common-color);
}

table th {
  border-right: 1px solid rgb(44, 4, 67);
}

table th:last-child {
  border-right: none;
}

/* Print */
@media print {
  .cart_page_table {
    width: 100%;
    padding: 10px;
  }

  .remove_cart_button, .count_btn{
    display: none !important;
  }
  .cart_page_table th {
    padding: 10px 5px;
    text-align: center;
  }
  .cart_page_table td {
    padding: 10px;
    text-align: center;
  }

  .table_header,
  .table_foot {
    background-color: rgb(215, 215, 215);
  }

  .cart_page_table th img {
    width: 20px;
  }

  .cart_prod_name {
    font-size: 14px;
    width: 100px;
  }

  .cart_row_btns {
    display: flex;
    align-items: center;
    gap: 5px;
  }

  .cart_row_btns .cart_row_btn {
    width: 20px;
    height: 20px;
    background-color: var(--common-color);
    display: flex;
    align-items: center;
    color: #fff;
    justify-content: center;
  }

  .cart_product {
    display: flex;
    align-items: center;
    gap: 5px;
  }

  .cart_product .product_img {
    width: 50px;
  }

  .cart_product .product_img img {
    width: 100%;
  }

  .cart_product .product_details {
    display: flex;
    flex-direction: column;
    align-content: space-between;
    gap: 10px;
  }

  .cart_product .product_details .product_name {
    font-size: 15px;
    font-weight: bolder;
  }

  .cart_product .product_details .product_count {
    color: gray;
    font-size: 12px;
    font-weight: bolder;
  }

  .table_foot {
    margin-top: 100%;
  }

  .cart_page_table.footer {
    position: fixed;
    bottom: 80px;
  }

  /* --------------------------------- */
  /* ------------NEW DESIGN----------- */
  /* --------------------------------- */
  .cart_content {
    margin-top: 20px;
    padding: 10px;
    margin-bottom: 100px;
    margin-bottom: 200px;
  }

  .cart_content .cart_product_2 {
    width: 100%;
    padding: 5px;
    border-radius: 20px;
    background-color: #fff;
    box-shadow: 0 0 10px #dadada;
    position: relative;
    margin-block: 10px;
  }

  .remove_cart_button {
    position: absolute;
    width: 25px;
    height: 25px;
    border-radius: 5px;
    background-color: hsl(0, 83%, 40%);
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    right: 0;
    color: #ffdddd;
  }

  .enVersion .remove_cart_button {
    left: 0;
    right: auto;
  }

  .cart_content .cart_product_2 .card_prod_top {
    display: flex;
    align-items: center;
    /* border: 1px solid; */
  }

  .cart_content .cart_product_2 .cart_prod_image {
    width: 100px;
    height: 100px;
    margin: 10px;
  }

  .cart_content .cart_product_2 .cart_prod_image img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    background-color: #d3d3d3;
    box-shadow: 0 0 10px #e0e0e0;
    border-radius: 10px;
  }

  .cart_prod_info {
    flex: 1;
  }

  .cart_prod_info h4 {
    font-size: 15px;
    color: #696969;
  }

  .cart_prod_info h5 {
    font-size: 20px;
    color: var(--common-color);
  }

  .cart_prod_info h5 span {
    font-weight: bolder;
  }

  .cart_product_2 .count_buttons {
    display: flex;
    height: 100%;
    flex-direction: column;
    align-items: center;
    align-content: space-between;
    justify-content: space-between;
    gap: 10px;
  }

  .cart_product_2 .count_buttons .count_btn {
    width: 30px;
    height: 30px;
    background-color: var(--common-color);
    color: #fff;
    align-items: center;
    justify-content: center;
    display: flex;
    cursor: pointer;
    border-radius: 10px;
    transition: 0.2s ease;
  }

  .cart_product_2 .count_buttons .count_btn:active {
    background-color: #450c7f;
  }

  .marketName {
    font-size: 12px;
  }

  .marketPrice {
    font-size: 12px;
  }

  .marketPrice.active {
    background-color: var(--common-color);
    color: #fff;
  }

  .fixed_subermarkets {
    position: fixed;
    bottom: 2px;
    left: 0;
    right: 0;
    transform: translateY(-100%);
    background-color: #fff;
    border-bottom: 2px solid var(--common-color);
  }

  table th {
    border-right: 1px solid rgb(44, 4, 67);
  }

  table th:last-child {
    border-right: none;
  }

  .fixed_subermarkets{
    display: none !important;
  }

  .none_subermarkets{
    display: flex !important
  }
}



