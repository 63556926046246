

.sup_cat_prods_section{
  background-color: #FFF;
  height: fit-content !important;
}

.sup_cat_prods_section:first-child{
  margin-top: 59px;
}

.sup_cat_prods_section:last-child{
  height: calc(100vh - 180px);
}

.section_products{
  display: grid;
  grid-template-columns: repeat(3 , 1fr);
  column-gap: 10px;
  row-gap: 20px;
  padding: 5px;
}

.sup_cat_prods_section .seciton_title{
    padding: 20px;
    font-size: 26px;
    
}