
*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "almarai" !important;

}

:root{
  --background-color:#ebebeb;
  --common-color:#ea6a2b;
}



@font-face {
  font-family: "almarai";
  src: url("./assets/almaraiFont/Almarai/Almarai-Regular.ttf"),
   url("./assets/almaraiFont/Almarai/Almarai-Bold.ttf"),
   url("./assets/almaraiFont/Almarai/Almarai-ExtraBold.ttf"),
   url("./assets/almaraiFont/Almarai/Almarai-Light.ttf"),
}


body{
  direction: rtl;
  /* background-color: var(--background-color) ; */
  font-family: "almarai" !important;

}


