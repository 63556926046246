

.header_start_icon{
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin: 10px;
  background-color: #eeeeee;
 font-size: 20px;
 display: flex;
 align-items: center;
 justify-content: center;

}