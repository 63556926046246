.footer_container{
  display: flex;
  align-items: center;
  justify-content: space-around;
  position: fixed;
bottom: 0;
left: 0;
right: 0;
height: 80px;
background-color: #fff;

}


.footer_container .footer_item{
  display: flex;
  align-items: center;
  flex-direction: column;
  gap:5px;
  color: #4b4b4b;
  position: relative;
}

.footer_container .footer_item.active .footer_icon{
  background-color: var(--common-color);
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  border-radius: 34px / 35px;

}

.footer_container .footer_item .footer_icon{
  font-size: 25px;
}

.footer_container .footer_item .footer_item_text{
  font-size: 14px ;
}

.footer_container .footer_item.active .footer_item_text{
  color: var(--common-color);
}


.footer_padge{
  position: absolute;
  top: 0px;
  left: -8px;
  background-color: #cdcdcd;
  margin: 0;
  min-width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--common-color);

 
}

