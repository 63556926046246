.storesContainer {
    display: flex;
    flex-direction: column;
    /* direction: ltr; */
    margin-bottom: 100px;
}

.storesContainer > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    border: 1px solid #D9D9D9;
}