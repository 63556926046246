
.product_details_container{
  padding: 0;
  margin: 0;
}

.product_details_container .prod_image{
  width: 100%;
  height: 250px;
  position: sticky;
  top: 100px;
  z-index: -1;

}


.product_details_container .prod_image img{
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.product_details_container .prod_data{
  padding: 10px 5px;
 box-shadow: 0 0 10px 2px #dadadadf;
 margin-top: 10px;
 border-radius: 30px 30px  0 0 ;
 background-color: #FFF;
 padding: 10px;
}

.product_details_container .prod_data .prod_name_price{
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
}

.prod_name_price  .prod_name{
  color: black;
  font-weight: bolder;
  font-size: 20px;
  color: #3d3d3d;
}


.prod_name_price .prod_price{
  color: var(--global-color);
  font-weight: bolder;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.prod_name_price .prod_price .price{
  font-size: 25px;
}


.prod_name_price .prod_price .pestPrice{
  font-size: 15px;
  color: rgb(2, 100, 49);
}

.counter_container{
  margin-top: 40px;
  display: flex;
  align-items: center;
  background-color: var(--global-color);
  width: fit-content;
  color: #FFF;
  font-weight: bolder;
  font-size: 20px;
  border-radius: 10px ;
  
}

.counter_container .digit_counter{
  padding-inline: 30px;
  /* border-top: none;
  border-bottom: none; */

  border-left: 1.5px solid var(--background-color) !important;
  border-right: 1.5px solid var(--background-color) !important;
  padding-block: 5px;
}


.counter_container .counter_icon{
  /* padding:10px; */
  padding-inline: 5px;
  
}


.counter_container .counter_icon.right.not_active{
  /* opacity: .5; */
  color: rgb(248, 105, 105);
  background-color: rgba(85, 85, 85, 0.34) !important;
}

.devider{
  height: 100%;
  width: 2px ;
  background-color: #FFF;
}


.success_message{
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  width:100%
}

.success_text{
  font-size: 20px;
  font-weight: bolder;
}


.popup_btns{
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  width:100%;
}

.close_btn , .list_btn{
  padding: 15px 20px;
  float: 1;
  border: 1px solid var(--global-color);
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--global-color);
  border-radius: 10px;
  width: 100%;
}

.list_btn{
  background-color: var(--global-color);
  color: #FFF;
}

.success_message_container{
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: 35px;
}




