

.add_to_cart_btn{
  padding: 20px;
  text-align: center;
  color: #FFF;
  background-color: var(--global-color);
  position: sticky;
  bottom: 0;
  font-size: 15px;
  font-weight: bolder;
  
}