
.prod_card {
  border: 1px solid #222;
  border-radius: 10px;
  overflow: hidden;
  width: 100%;
}

.prod_card .image{
  width: 100%;
  height: 150px;
  /* background-color: #FFF; */
}

.prod_card .image img{
  object-fit: cover;
  width: 100%;
  height: 100%;
}


.prod_card .prod_data{
  padding: 13px;
  font-size: 18px;
  font-weight: bolder;
}

.prod_card .cart_btn{
  text-align: center;
  font-weight: bolder;
  border-top: 2px solid;
  padding: 15px 5px;
}