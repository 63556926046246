.header_container{
  padding: 15px;
  position: sticky;
  top: 0 !important;
  background-color: #fff;
  z-index: 10000;
  /* box-shadow: 0 0 18px #d3d3d3; */
  border-bottom: 2px solid #d3d3d3;
}


.header_container .top_header{
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.header_container .header_icon{
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: rgb(219, 219, 219);
  transition: .3s ease;
}

.header_container .header_icon:active{
  background-color: rgb(193, 193, 193);
}

.header_container .header_icon.english{
  transform: rotate(180deg);
}


.header_container .page_name{
  font-size: 18px;
}

/* search pupup designs */


.searchHeader{
  position: relative;
}

.search_popup{
  background-color: #fff;
  padding: 10px;
  border-radius: 20px;
  box-shadow: 0 0 10px #c1c1c1;
  position: absolute;
 top: 100%; 
 width: 100%;
 max-height:calc(100vh - 250px) ;
 overflow-y: auto;
 
}

.search_popup{
  font-size: 15px;
  z-index: 10001 !important;
}



.search_popup .categories_items{
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  align-items: center;
  gap: 10px;
  width: 100%;
}

.search_category_card{
  width: 100%;
  height: 80px;
  border-radius: 20px;
  border: 1px solid #e1e1e1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* gap: 5px; */
}

.search_category_card .search_cat_card_image{
  width: 50px;
  height: 50px;
}

.search_category_card .search_cat_card_image img{  
  width: 100%;
  height: 100%;
  object-fit: contain;
  text-align: center;
  font-size: 12px;
}

.search_cat_card_text{
 text-align: center;
  font-size: 12px;
}


.search_products_items{
  display: grid;
  align-items: center;
  align-content: center;
  grid-template-columns: repeat(3 , 1fr);
  width: 100%;
}


.searchHeader {
  display: flex;
  gap: 9px;
  align-items: center;
}