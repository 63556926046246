.privacyContainer {
    display: flex;
    flex-direction: column;
    gap: 5px;
    direction: ltr;
    padding: 10px;
    margin-bottom: 80px;
}

.privacyContainer p {
    line-height: 1.7;
    letter-spacing: 1px;
}

.privacyContainer p a {
    text-decoration: none;
    color: #146c43;
}

.privacyContainer h5 {
    font-weight: 600;
    font-size: 14px;
    line-height: 1.6;
    margin: 10px 0px;
    padding: 0px;
}