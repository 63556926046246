.chooseLanguageContainer {
    display: flex;
    flex-direction: column;
}

.chooseLanguageContainer div {
    display: flex;
    border: 1px solid #d9d9d9;
    padding: 10px;
}

.chooseLanguageContainer div.active {
    background-color: var(--common-color);
}

.chooseLanguageContainer div p {
    margin: 0px;
}


