

.sub_cat_slider{
  display: flex;
  align-items: center;  
  padding-block: 10px;
  color: #5c5c5c;
  position: fixed;
  background-color: #fff;
  z-index: 1000;
  /* top: 0; */
  width: 100%;
}
.sub_cat_slider a{
  text-decoration: none;
  color: initial;
}
.sub_cat_slider .sub_cat_text{
  font-size: 15px;
  cursor: pointer;
}

.sub_cat_slider .sub_cat_text.active{
  color: var(--common-color);
  font-size: 14px;
  font-weight: bolder;
}

.sub_cat_slider_icon{
  margin-inline: 10px;
  font-size: 25px;
 
}

.sub_cat_border_bottom{
  width: 100%;
  height:4.5px;
  background-color: var(--common-color);
  border-radius: 4px 4px 0 0 ;
}