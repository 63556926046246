

.sub_cats_container{
  background-color: #e6e6e6;
  display: flex;
  gap: 10px;
  flex-direction: column;
  margin-bottom: 400px !important;
}


