


.country{
  /* background-color: red; */
  border-bottom: 2px solid  #cbcbcb !important;
  padding: 5px;
  transition: .2s ease;
}


.country.active{
  background-color: var(--common-color);
  color: #fff;
}


