.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  transform: scale(0);
  opacity: 0;
  transition:
    transform 0.3s,
    opacity 0.3s;

    z-index: 1000000;
}

.modal-overlay.noAnimation {
  transition: all 0s !important;
}

.modal-overlay.noOverLay {
  background: transparent;
}

.modal-overlay.show {
  opacity: 1;
  transform: scale(1);
}

.custom_modal {
  background: #fff;
  padding: 20px;
  border-radius: 30px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
  position: relative;
  transform: translateY(20px);
  opacity: 0;
  box-shadow: 0px 0px 10px #000000af;
  transition: all 0.3s ease-in-out;
}

.modal-overlay.noAnimation .custom_modal {
  transition: all 0s !important;
}

.custom_modal.show {
  transform: translateY(0);
  opacity: 1;
}

.modal-close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  border: none;
  background: transparent;
  font-size: 20px;
  font-weight: bold;
  color: #64040480;
  cursor: pointer;
  transition: color 0.2s;
}

.modal-close-button:hover {
  color: hsla(0, 92%, 20%, 0.976);
}

.modal-header {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  border-bottom: 1px solid #ccc;
  gap: 10px;
}

.modal-header h5 {
  margin: 10px 0;
  font-size: 18px;
}

.modal-buttons {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  margin-top: 10px;
}

.modal-buttons button {
  padding: 5px 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.modal-buttons .modal-confirm-button {
  background-color: #056d7f;
  color: #fff;
}

.modal-buttons .modal-cancel-button {
  background-color: #f44336;
  color: #fff;
}
