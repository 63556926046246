.priceAlertsContainer {
    display: flex;
    flex-direction: column;
}

.priceAlertsContainer div p {
    font-weight: 600;
}

.priceAlertsContainer div {
    padding: 3px 20px;
    display: flex;
    justify-content: space-between;
    gap: 10px;
    align-items: center;
    border-bottom: 1px solid #D9D9D9;
}