

.cateProds_container{
  padding: 0 20px ;
}

.subsubCats{
  padding: 20px 0;
  position: sticky;
  background-color: var(--background-color);
  top: 80px;
}