.defaultLayout{
    max-width: 100%;
    min-height: 100vh;
    overflow: hidden;
} 

.start_choose_city{
    height: 100vh;
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: space-between;
    
}

.bottom_sanve_btn{
    position:sticky;
    bottom: 0;
    display: flex;
    align-items: center;
    margin: 10px;
    justify-content: end;
    border-radius: 15px;
}

.bottom_sanve_btn button{
    /* border-radius: 0; */
    width: 100%;
}


