.priceCard_container{
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  align-items: center;
  justify-content: center;
  /* border: 1.5px solid ; */
  border-radius: 10px;
  box-shadow:  0 0 10px #cbcbcb;
  font-weight: bolder;
  font-size: 20px;
}

.priceCard_container .co_image{
 
  font-size: 20px;
  color: var(--common-color);
  font-weight: bolder;
  text-align: center;
}



.prices_header{
  font-weight: bolder;
  color: var(--global-color);
  margin: 10px;
  margin-bottom: 25px;
}