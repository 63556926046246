.category_cardd{
  background-image: url('./../../assets/images/greens.png');
  background-size: cover; /* Cover the entire element */
  background-color: rgba(161, 55, 55, 0.6);
  background-position: center; /* Center the image */
  height: 50px;
  position: relative;
  border-radius: 10px;
  overflow: hidden;
  transition: .2s ease;
}

.open{
  height: 130px;
}


.category_card .layout{
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(26, 26, 26, 0.724);
  z-index: 0;
  padding: 0 10px ;

}


.category_card .title{
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  color: #FFF;
  z-index: 1;

}


  .category_card .title .category_title{
    font-size: 16px;
  }

  .category_card .title  .icon{
    font-size: 25px;
  }


  /* .filters .filter_box {
    font-size: 12px;
  
    padding: 10px;
    box-shadow: 2px 2px 10px 2px #80808028;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 5px;
    border-radius: 5px;
    cursor: pointer;
    background-color: rgb(255, 255, 255);
    color: #222;
    border-radius: 5px;
    transition: 0.3s ease-in-out;
    font-weight: 900;
  }
   */

   .mySwiper{
    /* margin-top: 10px; */
   }

  .filter_box:hover {
    transform: scale(1.05);
    border-color: #fff9;
  }
  
  .filter_box:hover .icon {
    transform: translate(4px);
  }
  
  .filter_box:hover::before {
    animation: shine 1s ease-out infinite;
  }
  
  .filter_box::before {
    content: '';
    position: absolute;
    width: 100px;
    height: 100%;
    background-image: linear-gradient(
      120deg,
      rgba(255, 255, 255, 0) 30%,
      rgba(255, 255, 255, 0.8),
      rgba(255, 255, 255, 0) 70%
    );
    top: 0;
    left: -100px;
    opacity: 0.6;
  }
  
  @keyframes shine {
    0% {
      left: -100px;
    }
  
    60% {
      left: 100%;
    }
  
    to {
      left: 100%;
    }
  }
  
  .filters .filter_box span {
    font-size: 14px;
    font-weight: 700;
  }
  
  .filters .filter_box span:last-child {
    font-weight: 300;
    font-size: 14px;
  }
  
  header {
    max-width: 100%;
  }
  


  .swiper_slide{
    padding: 15px 20px;
    display: flex;
    align-items: center;
    gap: 5px;
    border-radius: 10px;
    border:1px solid #e34715;
    color: #e34715;

  }


  .slide_img{
    width: 30px;
    height: 30px;
    object-fit: cover;
  }
  
  .slide_img img{
    width: 100%;
  }