



.subSubCatSlide{
  padding: 15px;
  border-radius: 10px;
  background-color: rgb(218, 218, 218);
  font-size: 20px;
  font-weight: bold;
}


.subSubCatSlide.active{
  background-color: rgb(233, 71, 17);
  color: rgb(255, 255, 255);
}