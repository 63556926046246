.toggle-switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 20px;
}

.toggle-input {
  display: none;
}

.toggle-label {
  position: absolute;
  top: 0;
  left: 0;
  width: 40px;
  height: 20px;
  border-radius: 17px;
  background-color: #b8b8b886;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.toggle-label:before {
  content: "";
  position: absolute;
  top: -1.3px;
  left: -4px;
  width: 22px;
  height: 22px;
  border-radius: 50%;
  background-color: #fff;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  transition: transform 0.2s ease;
}

.toggle-input:checked + .toggle-label {
  /* background-color: rgb(22, 190, 92); */
  background-color: var(--common-color);
}

.toggle-input:checked + .toggle-label:before {
  transform: translateX(26px);
}