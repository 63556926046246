.contactUsContainer {
    display: flex;
    flex-direction: column;
}

.contactUsContainer div svg {
    width: 25px;
    height: 25px;
}

.contactUsContainer div {
    display: flex;
    gap: 14px;
    align-items: center;
    padding: 5px 30px;
    transition: all 0.3s;
}

.contactUsContainer div:hover p ,
.contactUsContainer div:hover  svg {
    color: #898C95;
}

.contactUsContainer div p {
    color: #000;
    font-weight: 600;
}

.contactUsContainer div {
    border: 1px solid #D9D9D9;
}