.settingsContainer {
    display: flex;
    flex-direction: column;
    margin-bottom: 120px;
}

.settingCard {
    display: flex;
    align-items: center;
    padding: 10px;
    border-bottom: 1px solid #898B9A;
    gap:20px
}

.settingCard:hover h3 , 
.settingCard:hover p ,
.settingCard:hover svg {
    color: #898C95 !important;
}



.settingCard .settingsContent {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.settingsContent h3 {
    color: #58264b !important;
    font-size: 16px;
}

.settingsContent p {
    color: #606060;
    font-weight: 600;
    font-size: 14px;
}

.settingCard .settingsContent h3 ,
.settingCard .settingsContent p{
    margin: 0px;
}

.settingCard svg {
    width: 30px;
    height: 30px;
}