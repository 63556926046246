.new_prod_card{
  width: 100%;
}

.np_prod_info{
  padding:  5px;
}
.np_prod_info .np_prod_name{
  display: -webkit-box; 
  -webkit-box-orient: vertical; 
  -webkit-line-clamp: 2; 
  overflow: hidden; 
  height: 38px; 
  width: 100%; 
  font-size: 12px; 
  color: #4f4f4f; 
  padding: 0;
  margin: 0;
}



.np_prod_info .np_prod_price{
  color: var(--common-color);
  font-size: 15px;
  font-weight: bolder;
}


.new_prod_card .np_image{
  width: 100%;
  height: 100px;
  position: relative;
}

.add_to_cart_button,
.remove_from_cart_button,
.cart_count_padge{
  position: absolute;
  width: 30px;
  height: 30px;
  background-color: var(--common-color);
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  border-radius: 10px;
  font-size: 20px;
}

.remove_from_cart_button{
  right: 0 !important;
}

.enVersion .remove_from_cart_button{
  right: 0 !important;
  left: auto !important;
}

.cart_count_padge{
  top: 0;
  color: #fff;
  border-radius: 4px;
  width: fit-content;
  padding-inline: 10px;

}


.new_prod_card .np_image img{
  width: 100%;
  height: 100%;
  object-fit: contain;
}