:root{
  --global-color:rgb(101, 9, 57)
  
}

.App {
  
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}




.filter_box:hover {
  transform: scale(1.05);
  border-color: #fff9;
}

.filter_box:hover .icon {
  transform: translate(4px);
}

.filter_box:hover::before {
  animation: shine 1s ease-out infinite;
}

.filter_box::before {
  content: '';
  position: absolute;
  width: 100px;
  height: 100%;
  background-image: linear-gradient(
    120deg,
    rgba(255, 255, 255, 0) 30%,
    rgba(255, 255, 255, 0.8),
    rgba(255, 255, 255, 0) 70%
  );
  top: 0;
  left: -100px;
  opacity: 0.6;
}

@keyframes shine {
  0% {
    left: -100px;
  }

  60% {
    left: 100%;
  }

  to {
    left: 100%;
  }
}


/* for swiper styles */

.filters .filter_box span {
  font-size: 14px;
  font-weight: 700;
}

.filters .filter_box span:last-child {
  font-weight: 300;
  font-size: 14px;
}

header {
  max-width: 100%;
}



.swiper_slide{
  padding: 15px 20px;
  display: flex;
  align-items: center;
  gap: 5px;
  border-radius: 10px;
  border:1px solid #e34715;
  color: #e34715;

}


.slide_img{
  width: 30px;
  height: 30px;
  object-fit: cover;
}

.slide_img img{
  width: 100%;
}



:where(.css-dev-only-do-not-override-1okl62o).ant-switch.ant-switch-checked {
  background-color: #58264b !important;
}

:where(.css-dev-only-do-not-override-1okl62o).ant-switch .ant-switch-handle::before  {
  border-radius: 50%;
  width: 19px;
  height: 19px;
  top: -0.5px;
}

:where(.css-dev-only-do-not-override-1okl62o).ant-switch {
  background-color: #b5b5b5 !important;
  border: 1px solid #959595;
}

:where(.css-dev-only-do-not-override-1okl62o).ant-switch .ant-switch-handle {
    /* width: fit-content;
    height: fit-content; */
    /* background-color: aqua; */
    border: none !important;
    /* width: 20px !important; */
    /* height: 20px !important; */
    border-radius: 300px;
}


.toastCon{
  z-index: 100000000 !important;
}

.active_row{
  background-color: var(--common-color);
  color: #fff;
}



.enVersion {
  direction: ltr !important;
}

/* body.enVersion p,
body.enVersion span,
body.enVersion a,
body.enVersion input,
body.enVersion input::placeholder {
  text-align: left !important;
}

.enVersion button.filterBtn.btn {
  text-align: left !important;
  direction: ltr !important;
}

.enVersion * {
  direction: ltr !important;
} */


/*
.enVersion .sidebar ul a {
  flex-direction: row-reverse;
} */

.enVersion .defaultLayout {
  direction: ltr;
}