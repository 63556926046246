.categories_container{
  margin-bottom: 100px;
}

.categories_container .first_four{
  display: grid;
  width: 100%;
  grid-template-columns: repeat(2, auto) ;
  gap: 10px;
}

.categories_container .first_four .category_card:nth-child(2){
  background-color: #afd6e4 ;
  grid-column:2/4 ;
  /* grid-row: 1/5; */
}

.categories_container .first_four .category_card:nth-child(3){
  grid-column:1/3 ;

}

.category_card.small .cat_card_text{
  text-align: center;
  font-size: 12px;
}



.category_card{
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  justify-content: center;
  height: 120px;
  gap: 4px;
  /* border: 1px solid; */
   border-radius: 20px;
   background-color: #e1f4f6;
   box-shadow: 0px 2px 15px -6px #82abb4;
   border: 1px solid #dfdfdf;
   padding: 10px;
}


.category_card.big{
  width: auto ;
  flex-direction: row;
  align-items:start;
  justify-content: space-between;
}

.category_card.big .cat_card_image{
  width: 90%;
  height: 100%;
}



.category_card >div :first-child{
  width: 60%;
}

.category_card > div :last-child{
  width: 40%;
}



.category_card.big{
  /* flex: 2; */
}
.category_card .cat_card_image{
  width:80px
  
}

.category_card .cat_card_image img{
  width: 100%;
  height: 100%;
  object-fit: contain;
}


.categories_container .after_first_four{
  margin-top: 10px;
  display: grid;
  width: 100%;
  grid-template-columns: repeat(3, auto) ;
  gap: 10px;}
  